import React from "react";
import logo from "../../assets/balcovalogo.gif";

const Navbar = () => {
  return (
    <div>
      <div className="bg-white p-2 ">
        <div className=" flex flex-row items-center justify-between mx-16">
          <div>
            <img src={logo} />
          </div>
          <div className="flex flex-row gap-x-10 items-center">
            <div className="text-base text-customNavbarTextColor font-bold text-lg cursor-pointer">
              Anasayfa
            </div>
            <a className="text-base text-customNavbarTextColor font-bold text-lg cursor-pointer">
              E-Belediye
            </a>
            <a
              href="https://www.balcova.bel.tr/iletisim.aspx"
              className="text-base text-customNavbarTextColor font-bold text-lg cursor-pointer"
            >
              İletişim
            </a>
            <div className="flex w-96 rounded bg-white">
              <input
                type="text"
                name="search"
                id="search"
                placeholder="Size nasıl yardımcı olabiliriz?"
                className="w-full border-2 bg-transparent p-4 text-gray-900  rounded-md"
              />
              <button className="m-2 rounded bg-teal-800 px-5 py-2 text-white absolute left-left91 top-top3">
                Ara
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
