import React from "react";
import { FaPhoneVolume } from "react-icons/fa6";
import { FaFax } from "react-icons/fa";
import { IoMailSharp } from "react-icons/io5";
import { IoIosArrowForward } from "react-icons/io";

const Footer = () => {
  return (
    <div className="bg-customNavbarTextColor">
      <div className="flex flex-row justify-around p-4">
        <div className="flex flex-row items-center gap-4">
          <FaPhoneVolume className="text-3xl text-white" />
          <div className="text-white text-xl font-bold">0232 455 20 00</div>
        </div>
        <div className="flex flex-row items-center gap-4">
          <FaFax className="text-3xl text-white" />
          <div className="text-white text-xl font-bold">0232 455 20 00</div>
        </div>
        <div className="flex flex-row items-center gap-4">
          <IoMailSharp className="text-3xl text-white" />
          <div className="text-white text-xl font-bold">
            balcova@balcova.bel.tr
          </div>
        </div>
      </div>
      <div className="border-b-2 mx-10 border-[#989898]">

      </div>
      <div className="flex flex-row justify-around ">
        <div className="flex flex-col my-5">
          <div className="text-xl border-b-2 text-center text-white border-[#989898]">Menü</div>
          <div className="flex flex-col">
            <div className="flex flex-col mt-2 gap-1">
              <div className="flex flex-row items-center">
                <IoIosArrowForward className="text-white" />
                <div className="text-white">E-Belediye</div>
              </div>
              <div className="flex flex-row items-center">
                <IoIosArrowForward className="text-white" />
                <div className="text-white">Projeler</div>
              </div>
              <div className="flex flex-row items-center">
                <IoIosArrowForward  className="text-white"/>
                <div className="text-white">İhaleler</div>
              </div>
              <div className="flex flex-row">
                <IoIosArrowForward className="text-white" />
                <div className="text-white">İletişim</div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col my-5">
          <div className="text-xl border-b-2 text-center text-white border-[#989898]">
            Balçova
          </div>
          <div className="flex flex-col mt-2 gap-1">
            <div className="flex flex-row items-center">
              <IoIosArrowForward className="text-white" />
              <div className="text-white">Genel Bilgiler</div>
            </div>
            <div className="flex flex-row items-center">
              <IoIosArrowForward className="text-white" />
              <div className="text-white">Tarihçe</div>
            </div>
            <div className="flex flex-row items-center">
              <IoIosArrowForward  className="text-white"/>
              <div className="text-white">Nüfus Kaydı</div>
            </div>
            <div className="flex flex-row items-center">
              <IoIosArrowForward  className="text-white"/>
              <div className="text-white">Fotoğraflar</div>
            </div>
            <div className="flex flex-row items-center">
              <IoIosArrowForward className="text-white" />
              <div className="text-white">Balçova 360</div>
            </div>
            <div className="flex flex-row items-center">
              <IoIosArrowForward className="text-white"/>
              <div className="text-white">Kameralar</div>
            </div>
          </div>
        </div>
        <div className="flex flex-col my-5">
          <div className="text-xl border-b-2 text-center text-white border-[#989898]">
            Belediye
          </div>
          <div className="flex flex-col mt-2 gap-1">
            <div className="flex flex-row items-center">
              <IoIosArrowForward className="text-white" />
              <div className="text-white">Hizmetler</div>
            </div>
            <div className="flex flex-row items-center">
              <IoIosArrowForward className="text-white" />
              <div className="text-white">Balçova TV</div>
            </div>
            <div className="flex flex-row items-center">
              <IoIosArrowForward  className="text-white"/>
              <div className="text-white">Toplanma Alanları</div>
            </div>
            <div className="flex flex-row items-center">
              <IoIosArrowForward className="text-white" />
              <div className="text-white">Mail Listesi</div>
            </div>
            <div className="flex flex-row items-center">
              <IoIosArrowForward className="text-white" />
              <div className="text-white">Parklar Haritası</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
